import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { withTheme } from 'styled-components'

import { useDemo } from '../hooks'
import { SEO } from '../components/atoms'
import Hero from '../components/molecules/Hero'
import Integrations from '../components/molecules/Integrations'
import Features from '../components/molecules/Features'
import LearnMore from '../components/molecules/LearnMore'
import Customers from '../components/molecules/Customers'
import Apps from '../components/molecules/Apps'

export const query = graphql`
  query demoRegistrationPageQuery($appVersion: String!) {
    site {
      siteMetadata {
        tagline
      }
    }
    allMdx(filter: { frontmatter: { version: { eq: $appVersion } } }) {
      edges {
        node {
          body
          frontmatter {
            version
            date
            until
            category
            template
            tags
            title
            modal
            link
          }
        }
      }
    }
  }
`

export default withTheme(({
  theme,
  location: {
    pathname,
  } = {},
  data: {
    site: {
      siteMetadata: {
        tagline,
      } = {},
    } = {},
    ...data
  } = {},
  pageContext: {
    fragments,
  } = {},
}) => {
  const { modal, openModal } = useDemo({ pathname, breakpoints: theme?.breakpoints })

  useEffect(() => {
    openModal()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SEO title={`Demo Register: ${tagline}`} keywords="demo, demonstration, product demo, live demo, group demo" />
      <Hero
        relative
        mode="fullscreen"
        format="medium"
        data={data}
      />
      <Customers
        mode="overlay"
        format="light"
        backdrop="light"
      />
      <Features offset format="light" />
      <Integrations relative format="default" />
      <Apps relative format="light" fragments={fragments} />
      <LearnMore
        id="learn-more"
        mode="overlay"
        max="sm"
        format="light"
      />
      {modal}
    </>
  )
})
